import React ,{Fragment,Suspense,useEffect,useState}from 'react';
import {Route,Routes,Navigate} from 'react-router-dom';
import {ErrorBoundary} from "react-error-boundary";
import {ErrorFallbackComponent} from "./ErrorBoundary";
import {ClipLoader} from "react-spinners";
import {Cookies} from "./HttpServercall";



const UserappFrame =  React.lazy(()=>import("./UserappFrame/UserappFrame"))

const AuthenticationApp =  React.lazy(()=>import("./AuthenticationApp/AuthenticationApp"))



const  AppNavigation = (props) => {
//eslint-disable-next-line
const [onProduction,setonProduction] = useState(()=>{
  var on_production = false
  if((process.env.NODE_ENV==='production') && (window.location.hostname!=='localhost') && (window.location.hostname!=='127.0.0.1')){
   
   on_production = true
  }
  return on_production
})




const [UserAuthenticated,setUserAuthenticated] = useState(()=>{
  var AuthenticationToken  = Cookies.get(`ux-csrftoken`);
  var ActiveApplication = localStorage.getItem(`active-appxn`);
   if(AuthenticationToken !=null){

     return true
    }else{


    return false
   } 
})




useEffect(()=>{//COMPONENT DID MOUNT



},[])











return ( //=----RETURN MAIN HTML NAVIGATION
<ErrorBoundary FallbackComponent={ErrorFallbackComponent}  onReset={()=>{window.location.reload()}}>
<Suspense fallback={<div id='GlobalApplicationLoading' style={{width:'100vw',height:'100vh',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><ClipLoader size={50} /></div>}>
<Routes>
  
    <Route path="/authentication/*"  element={<AuthenticationApp  USER_TYPE='admin' />} /> 

    <Route path="*" element={UserAuthenticated?<UserappFrame USER_TYPE='admin' />:<Navigate to={`/authentication/selectaccount`} replace /> } />
  
  </Routes>
</Suspense>
</ErrorBoundary>
);
}
export {AppNavigation};

