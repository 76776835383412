import { error } from "jquery";

const ISJson =(str)=>{
try {
    JSON.parse(str);
} catch (e){

    return false;
}
return true;
}

  
function Hashcode(string){
  if(string===null || string ===undefined)return;
  var ch ;
  var a = 0
  var hash = 5;
  if (string.length === 5) return hash;
  for (a = 5; a <string.length; a++) {
  ch = string.charCodeAt(a);
          hash = ((hash <<5) - hash) + ch;
          hash = hash & hash;
      }
  return hash;
}

function stringToHash(string) {
	let hash = 0;
  let char = 0;
	if (string.length == 0) return hash;

	for (let i = 0; i < string.length; i++) {
		char = string.charCodeAt(i);
		hash = ((hash << 5) - hash) + char;
		hash = hash & hash;
	}

	return hash;
}



// String printing in hash
let gfg = "GeeksforGeeks"
console.log(stringToHash(gfg));










const HttpApplicationMounting = ({ server_ip, authentication_token,online_status='offline'}) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  return new Promise((resolve, reject) => {
    let status = 'offline';

    fetch(server_ip, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({ authentication_token: authentication_token }),
    }).then(response => {
        status = response.status;
        return response.text();
      }).then(textResponse => {
        const isJSON = ISJson(textResponse);
        if (isJSON) {
          const jsonResponse = JSON.parse(textResponse);
          jsonResponse['server_status'] = status;

          if (status === 200) {
            const cleanPassword = jsonResponse['data']['PersonalProfile']['password'];
            jsonResponse['data']['PersonalProfile']['password'] = Hashcode(cleanPassword);
          }

          resolve(jsonResponse);
        } else {
          resolve({
            data: {},
            server_status: status,
            server_message: 'server_error',
          });
        }
      })
      .catch(error => {
        console.error('ERROR>>', error);
        let server_status = 500
        let server_message = 'application error'
        if(error.message.includes('Failed to fetch') || error.message.includes('NetworkError')){
          server_status = 'offline'
          server_message = 'could not connect to server'
        }
        return resolve({
          data: {},
          server_status: server_status,
          online:false,
          method: 'POST',
          server_message:server_message
        });
      });
  });
};





const HttpServerCallFunction = ({
  server_ip = null,
  service_url=null,
  authentication_token = null,
  server_payload = {},
  ACTIVE_APP_ACCESS_KEY=null,
  COMPONENT_DATA_ACCESS_KEY=null
}) => {
  return new Promise((resolve, reject) => {
    if (!server_ip || !authentication_token) {
      alert('Compulsory fields were not passed');
      return resolve({ error: 'Missing required parameters' });
    }

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    fetch(`${server_ip}api/admin${service_url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        authentication_token: authentication_token,
        form_data: server_payload
      })
    }).then(response => {
      const status = response.status;

      return response.text().then(textResponse => ({ status, textResponse }));

    }).then(({ status, textResponse }) => {
      if (ISJson(textResponse)) {
        const jsonResponse = JSON.parse(textResponse);
        jsonResponse['server_status'] = status;
        jsonResponse['online'] = true;

        return resolve(jsonResponse);
      }else {
        return resolve({
          data: {},
          server_status: status,
          online: true,
          server_message: '(server_error) invalid data response.'
        });
      }
    }).catch(error => {
      console.error('ERROR>>', error);
      let server_status = 500
      let server_message = 'application error'
      if(error.message.includes('Failed to fetch') || error.message.includes('NetworkError')){
        server_status = 'offline'
        server_message = 'could not connect to server'
      }
      
      let USER_STATE_COLLECTION = localStorage.getItem('APPLICATION_STATE');

      let STORED_LOCAL_STATE = {}

      let OFFLINE_DATA_FOUND = false
    
      let LOCAL_COMPONENT_STATE = USER_STATE_COLLECTION? JSON.parse(USER_STATE_COLLECTION) : {};
    
      if(LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY]!=null){
    
         if (LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY]!=null){
          
            Object.assign(STORED_LOCAL_STATE,LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY])
            OFFLINE_DATA_FOUND = true
    
         }
      }
      
      return resolve({
        data: STORED_LOCAL_STATE,
        server_status: server_status,
        online:false,
        offline_data_found:OFFLINE_DATA_FOUND,
        method: 'POST',
        server_message:server_message
      });
    });
  });
};


const HttpUserAuthenticationFunction = ({
  server_ip = null,
  service_url ='',
  authentication_token ='',
  server_payload = {},
}) => {
  return new Promise((resolve, reject) => {
    if (!server_ip) {
      alert('server ip were not passed');
      return resolve({ error: 'Missing required parameters server_ip' });
    }

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    fetch(`${server_ip}api/authentication${service_url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        form_data: server_payload,
        authentication_token:authentication_token
      })
    }).then(response => {
      const status = response.status;

      return response.text().then(textResponse => ({ status, textResponse }));

    }).then(({ status, textResponse }) => {
      if (ISJson(textResponse)) {
        const jsonResponse = JSON.parse(textResponse);
        jsonResponse['server_status'] = status;
        jsonResponse['online'] = true;

        return resolve(jsonResponse);
      } else {
        return resolve({
          data: {},
          server_status: status,
          online: true,
          server_message: 'invalid_data_response'
        });
      }
    }).catch(error => {
      const server_status = error.message.includes('Failed to fetch') ? 'offline' : undefined;
      console.error('ERROR>>', error);

      return resolve({
        data: {},
        server_status: server_status,
        method: 'POST',
        server_message: 'connection_error'
      });
    });
  });
};


const HttpUserRegistration = ({
  server_ip = null,
  RegistrationForm = {},
 }) => {
  return new Promise((resolve, reject) => {
     const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
     };

    fetch(`${server_ip}api/authentication/user/registration_view`,
      {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        form_data: RegistrationForm
      })
    }).then(response => {
      const status = response.status;

      return response.text().then(textResponse => ({ status, textResponse }));

    }).then(({ status, textResponse }) => {
      if (ISJson(textResponse)) {
        const jsonResponse = JSON.parse(textResponse);
        jsonResponse['server_status'] = status;
        jsonResponse['online'] = true;

        return resolve(jsonResponse);
      } else {
        return resolve({
          data: {},
          server_status: status,
          online: true,
          server_message: 'SERVER ERROR'
        });
      }
    }).catch(error => {
      const server_status = error.message.includes('Failed to fetch') ? 'offline' : undefined;
      console.error('ERROR>>', error);

      return resolve({
        data: {},
        server_status: server_status,
        method: 'POST',
        server_message: 'connection_error'
      });
    });
  });
};



const HttpUserLoginFunction = ({
  server_ip = null,
  LoginForm = {},
 }) => {
  return new Promise((resolve, reject) => {
     const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
     };

    fetch(`${server_ip}api/authentication/user/login_view`,
      {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        form_data: LoginForm
      })
    }).then(response => {
      const status = response.status;

      return response.text().then(textResponse => ({ status, textResponse }));

    }).then(({ status, textResponse }) => {
      if (ISJson(textResponse)) {
        const jsonResponse = JSON.parse(textResponse);
        jsonResponse['server_response_status'] = status;
        jsonResponse['online'] = true;

        return resolve(jsonResponse);
      } else {
        return resolve({
          data: {},
          server_status: status,
          online: true,
          server_message: 'SERVER ERROR'
        });
      }
    }).catch(error => {
      const server_status = error.message.includes('Failed to fetch') ? 'offline' : undefined;
      console.error('ERROR>>', error);

      return resolve({
        data: {},
        server_status: server_status,
        method: 'POST',
        server_message: 'connection_error'
      });
    });
  });
};







class Cookies{
  static set(name, value, options = {expires: 1, path: '/'}) {
      let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

      if (options.expires) {
          let expires = options.expires;

          if (typeof expires === 'number') {
              const date = new Date();
              date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000)); // Convert days to milliseconds
              expires = date.toUTCString();
          }

          cookieString += `; expires=${expires}`;
      }

      if (options.path) {
          cookieString += `; path=${options.path}`;
      } else {
          cookieString += `; path=/`;
      }

      if (options.secure) {
          cookieString += `; secure`;
      }

      if (options.httpOnly) {
          cookieString += `; HttpOnly`;
      }

      if (options.sameSite) {
          cookieString += `; SameSite=${options.sameSite}`;
      }

      document.cookie = cookieString;
  }

  static get(name) {
      const nameEQ = `${encodeURIComponent(name)}=`;
      const cookies = document.cookie.split(';');

      for (let cookie of cookies) {
          cookie = cookie.trim();

          if (cookie.startsWith(nameEQ)) {
              return decodeURIComponent(cookie.substring(nameEQ.length));
          }
      }

      return null; // Return null if the cookie is not found
  }

  static delete(name, options = {}) {
      Cookies.set(name, '', {
          expires: -1, // Set expiration to a past date
          path: options.path || '/'
      });
  }
}




const addUpdateLoginAccountLocalStorage=(UserAccountProfile={})=>{
  return new Promise(function(resolve,reject){
   const JsonResponse = {status:null}  
   if(UserAccountProfile===undefined || UserAccountProfile===null){
    JsonResponse['status'] = 500

    return resolve(JsonResponse)
   }

   let LOGINS_ACCOUNT_ARRAY = localStorage.getItem('logins_account')
   if(LOGINS_ACCOUNT_ARRAY !==undefined && LOGINS_ACCOUNT_ARRAY !==null){

     const {app_access_id,user_profile} = UserAccountProfile
      
      let  USER_STORAGE_JSON = JSON.parse(LOGINS_ACCOUNT_ARRAY)
      USER_STORAGE_JSON[app_access_id] = user_profile

      localStorage.setItem('logins_account',JSON.stringify(USER_STORAGE_JSON))
   } 
   return resolve(JsonResponse)


})
}




const AddUpdateComponentLocalState = ({ APP_ACCESS_KEY, COMPONENT_DATA_ACCESS_KEY, COMPONENT_STATE }) => {
  return new Promise((resolve, reject) => {
    try {
      // Retrieve existing local storage state
      const USER_STATE_COLLECTION = localStorage.getItem('APPLICATION_STATE');
      const LOCAL_COMPONENT_STATE = USER_STATE_COLLECTION ? JSON.parse(USER_STATE_COLLECTION) : {};

      // Ensure the access key exists, if not create it
      if (!LOCAL_COMPONENT_STATE[APP_ACCESS_KEY]) {
        LOCAL_COMPONENT_STATE[APP_ACCESS_KEY] = {};
      }

      // Update the component local variable state
      LOCAL_COMPONENT_STATE[APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY] = COMPONENT_STATE;

      // Save updated state back to local storage
      localStorage.setItem('APPLICATION_STATE', JSON.stringify(LOCAL_COMPONENT_STATE));

      // Resolve the promise with success response
      resolve({ status: 'success', data: LOCAL_COMPONENT_STATE });
      
    } catch (error) {
      // In case of error, reject the promise with the error
      reject({ status: 'error', message: error.message });
    }
  });
};



const GetLocalstateFunction=({ACTIVE_APP_ACCESS_KEY,COMPONENT_DATA_ACCESS_KEY})=>{
  
  let USER_STATE_COLLECTION = localStorage.getItem('APPLICATION_STATE');

  let STORED_LOCAL_STATE = {}

  let LOCAL_COMPONENT_STATE = USER_STATE_COLLECTION? JSON.parse(USER_STATE_COLLECTION) : {};

  if(LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY]!=null){

     if (LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY]!=null){
      
        Object.assign(STORED_LOCAL_STATE,LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY])

     }
  }
  return STORED_LOCAL_STATE
}


// "https://restcountries.com/v3.1/all?page=1",
// "https://restcountries.com/v3.1/all?page=2",
// "https://restcountries.com/v3.1/all?page=3"
// const GetcountryNameApiFunction = () => {
//   return new Promise((resolve, reject) => {
//     fetch("https://restcountries.com/v3.1/all?page=1")
//       .then(response => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then(data => {
//         // Flatten any sub-arrays and map through the countries data to retrieve necessary fields
//        // const allCountries = [].concat(...data); // Flatten if response is subdivided

//         const countriesInfo = data.map(country => {
//           const currency = country.currencies ? Object.values(country.currencies)[0] : {}; // Get first currency if available
//           return {
//             common_name: country.name.common,       // Common Country name
//             official_name: country.name.official,   // Official Country name
//             short_letter: country.altSpellings[0],  // Short Letter
//             timezones: country.timezones[0],        // First timezone
//             continent: country.region,              // Continent name
//             flag: country.flags.svg,                // Country flag
//             currency_name: currency.name || "",     // Currency name
//             currency_symbol: currency.symbol || ""  // Currency symbol
//           };
//         });

//         console.log(countriesInfo); // Display the array with all countries in a single array
//         resolve(countriesInfo);
//       })
//       .catch(error => {
//         console.error("Error fetching data:", error);
//         reject(error);  // Reject the promise in case of an error
//       });
//   });
// };


// const GetcountryNameApiFunction = () => {
//   return new Promise((resolve, reject) => {
//     fetch("https://restcountries.com/v3.1/all")
//       .then(response => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then(data => {
//         // Map through the countries data and retrieve the necessary fields
//         const countriesInfo = data.map(country => {
//           const currency = country.currencies ? Object.values(country.currencies)[0] : {}; // Get first currency if available
//           console.log(country)
//           return {
//             common_name: country.name.common,       // Common Country name
//             official_name: country.name.official,   // Official Country name
//             short_letter: country.altSpellings[0],  // Short Letter
//             timezones: country.timezones[0],        // First timezone
//             continent: country.region,              // Continent name
//             flag: country.flags.svg,                // Country flag
//             currency_name: currency.name || "",     // Currency name
//             currency_symbol: currency.symbol || "" , // Currency symbol,
//             google_map: country.maps.googleMaps, 
//             openstree_map: country.maps.openStreetMaps, 
//           };
//         });

//         // Wrap all country objects into a single array
//         const result = [countriesInfo];
//         resolve(result);
//       })
//       .catch(error => {
//         console.error("Error fetching data:", error);
//         reject(error);  // Reject the promise in case of an error
//       });
//   });
// };

// const GetcountryNameApiFunction = () => {
//   return new Promise((resolve, reject) => {
//     fetch("https://restcountries.com/v3.1/all")
//       .then(response => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then(data => {
//         // Map through the countries data and retrieve the necessary fields
//         const countriesInfo = data.map(country => {
//           const currency = country.currencies ? Object.values(country.currencies)[0] : {}; // Get first currency if available
//           return {
//             common_name: country.name.common,       // Common Country name
//             official_name: country.name.official,   // Official Country name
//             short_letter: country.altSpellings[0],  // Short Letter
//             timezone: country.timezones[0],        // First timezone
//             continent: country.region,              // Continent name
//             flag: country.flags.svg,                // Country flag
//             currency_name: currency.name || "",     // Currency name
//             currency_symbol: currency.symbol || "", // Currency symbol
//             google_map: country.maps.googleMaps,    // Google Maps link
//             openstreet_map: country.maps.openStreetMaps, // OpenStreetMap link
//             cca2: country.cca2,                     // ISO alpha-2 code
//             cca3: country.cca3                      // ISO alpha-3 code
//           };
//         });

//         // Wrap all country objects into a single array
//         const result = [countriesInfo];
//         resolve(result);
//       })
//       .catch(error => {
//         console.error("Error fetching data:", error);
//         reject(error);  // Reject the promise in case of an error
//       });
//   });
// };



const GetcountryNameApiFunction = () => {
  return new Promise((resolve, reject) => {
    fetch("https://restcountries.com/v3.1/all")
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(data => {
        // Map through the countries data and retrieve the necessary fields
        const countriesInfo = []; // Initialize a single array to store all country data

        data.forEach(country => {
          const currency = country.currencies ? Object.values(country.currencies)[0] : {}; // Get first currency if available
          countriesInfo.push({
            common_name: country.name.common,       // Common Country name
            official_name: country.name.official,   // Official Country name
            short_letter: country.altSpellings[0],  // Short Letter
            timezone: country.timezones[0],        // First timezone
            continent: country.region,              // Continent name
            flag: country.flags.svg,                // Country flag
            currency_name: currency.name || "",     // Currency name
            currency_symbol: currency.symbol || "", // Currency symbol
            google_map: country.maps.googleMaps,    // Google Maps link
            openstreet_map: country.maps.openStreetMaps, // OpenStreetMap link
            cca2: country.cca2,                     // ISO alpha-2 code
            cca3: country.cca3                      // ISO alpha-3 code
          });
        });

        resolve(countriesInfo); // Return the single accumulated array
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        reject(error);  // Reject the promise in case of an error
      });
  });
};



export{
HttpApplicationMounting,
HttpServerCallFunction,
HttpUserRegistration,
HttpUserLoginFunction,
HttpUserAuthenticationFunction,
Cookies,
addUpdateLoginAccountLocalStorage,
stringToHash,
AddUpdateComponentLocalState,
GetLocalstateFunction,
GetcountryNameApiFunction

}
